import { useState, useRef, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { HeaderMobile } from './headermobile'
import { useTranslation } from 'react-i18next'
import WhiteLabel from '../util/WhiteLabel'
import { ServiceMenu } from '../components/Menu/service_menu'
import { CompanyMenu } from '../components/Menu/company_menu'
import { MobileMenu } from '../components/Menu/mobile_menu'

const Header: React.FC = ({ children }) => {
  let [mobile, setMobil] = useState<boolean>(false)
  let [services, setServices] = useState<boolean>(false)
  let [company, setCompany] = useState<boolean>(false)
  let location = useLocation()
  const wrapperRef = useRef(null)

  let { LOGO } = WhiteLabel()
  const { t } = useTranslation()


  let useOutsideFunction = (ref: any) => {

    let handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setServices(false)
        setCompany(false)
        setMobil(false)
      }
    }
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [])
  }
  useEffect(() => {
    window.addEventListener('scroll', closeMenu)
    return () => window.removeEventListener('scroll', closeMenu)
  }, [])

 let closeMenu =()=> {
    setServices(false)
    setCompany(false)
  }

  let mobilemenu = () => {
    return setMobil(true)
  }
  useOutsideFunction(wrapperRef)

  return (
    <div className="header">
      <div className="header__context">
        <div>
          <Link to="/home">
            <img className="header__top-moneyimg" src={LOGO} alt="no" />
          </Link>
        </div>
        <div className="header__right-menu">
          <div
            className={`${location.pathname === '/services'
              ? 'header__isactive'
              : 'header__focus'
              }`}
            tabIndex={0}
            onClick={() => {
              setServices(!services); setCompany(false)
            }}
          >
            {t('Services')}
          </div>
          <div
            tabIndex={0}
            className={`${location.pathname === '/company'
              ? 'header__isactive'
              : 'header__focus'
              }`}
            onClick={() => {
              setCompany(!company); setServices(false)
            }}
          >
            {t('Company')}
          </div>
        </div>
        <div className="header__mobile">
          <HeaderMobile>{mobilemenu}</HeaderMobile>
        </div>
      </div>

      {services && <ServiceMenu wrapperRef={wrapperRef} />}
      {company && <CompanyMenu wrapperRef={wrapperRef} />}
      {mobile && <MobileMenu wrapperRef={wrapperRef} setMobil={setMobil} />}
    </div>
  )
}
export default Header


