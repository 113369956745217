import { Globalmoneycoutry } from './globalmoney'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import WhiteLabel from '../util/WhiteLabel'

let { GLOBAL_TRANSFER_SHADOW, GLOBAL_TRANSFER_GIF, APP_NAME } = WhiteLabel()
export const Send = () => {
  const { t } = useTranslation()
  useEffect(() => {
    window.scrollTo(0, 100)
  }, [])
  return (
    <div id="target1" className="pos">
      <div>
        <img
          src={GLOBAL_TRANSFER_SHADOW}
          alt="no"
          className="services__transfer"
        />
      </div>
      <div className="services">
        <div className="services__info">
          <h1 className={`services__${APP_NAME}-title`}>
            <p>{t('Global_Money')}</p>
            <p>{t('t')}</p>
          </h1>
          <div className="services__text-dot">
            <div>{t('text_dot')}</div>
          </div>
          <div className="services__text-info">{t('Global_money_text')}</div>
          <Globalmoneycoutry />
        </div>
        <div>
          <div>
            <img
              src={GLOBAL_TRANSFER_GIF}
              alt="..loading"
              className="services__video-sz"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
