import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { AppWrapper } from '../components/AppWrapper'

export const CookiePrivacy: React.FC = () => {
  const { t } = useTranslation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <AppWrapper>


      <div className="privacy">
        <div className="privacy__policy">
          <div>
            <div className="privacy__text">
              <p>Cookies Policy</p>
              <p>Last updated: March 29, 2022</p>
              <p>
                This Cookies Policy explains what Cookies are and how We use them.
                You should read this policy so You can understand what type of
                cookies We use, or the information We collect using Cookies and
                how that information is used.
              </p>
              <p>
                Cookies do not typically contain any information that personally
                identifies a user, but personal information that we store about
                You may be linked to the information stored in and obtained from
                Cookies. For further information on how We use, store and keep
                your personal data secure, see our Privacy Policy.
              </p>
              <p>
                We do not store sensitive personal information, such as mailing
                addresses, account passwords, etc. in the Cookies We use
              </p>
              <h1>{t('interpretation_u')}</h1>
              <h3>{t('Interpretation')}</h3>
              <p>{t('The_words')}</p>
              <h3>{t('Definitions')}</h3>
              <p>For the purposes of this Cookies Policy: </p>
              <ul>
                <li>
                  Cookies means small files that are placed on Your computer,
                  mobile device or any other device by a website, containing
                  details of your browsing history on that website among its many
                  uses.
                </li>
                <li>Website refers to Robert Change, accessible from robert-change.com</li>
                <li>
                  You means the individual accessing or using the Website, or a
                  company, or any legal entity on behalf of which such individual
                  is accessing or using the Website, as applicable
                </li>
              </ul>
              <h1>The use of the Cookies</h1>
              <h4>Type of Cookies We Use</h4>
              <p>
                Cookies can be "Persistent" or "Session" Cookies. Persistent
                Cookies remain on your personal computer or mobile device when You
                go offline, while Session Cookies are deleted as soon as You close
                your web browser.
              </p>
              <p>
                We use both session and persistent Cookies for the purposes set
                out below:
              </p>
              <h4>Necessary / Essential Cookies</h4>
              <p>Type: Session Cookies</p>
              <p>Administered by: Us</p>
              <p>
                Purpose: These Cookies are essential to provide You with services
                available through the Website and to enable You to use some of its
                features. They help to authenticate users and prevent fraudulent
                use of user accounts. Without these Cookies, the services that You
                have asked for cannot be provided, and We only use these Cookies
                to provide You with those services.
              </p>
              <h4>Functionality Cookies</h4>
              <p>Type: Persistent Cookies</p>
              <p>Administered by: Us</p>
              <p>
                Purpose: These Cookies allow us to remember choices You make when
                You use the Website, such as remembering your login details or
                language preference. The purpose of these Cookies is to provide
                You with a more personal experience and to avoid You having to
                re-enter your preferences every time You use the Website.
              </p>
              <h4>Your Choices Regarding Cookies</h4>
              <p>
                If You prefer to avoid the use of Cookies on the Website, first
                You must disable the use of Cookies in your browser and then
                delete the Cookies saved in your browser associated with this
                website. You may use this option for preventing the use of Cookies
                at any time
              </p>
              <p>
                If You do not accept Our Cookies, You may experience some
                inconvenience in your use of the Website and some features may not
                function properly.
              </p>
              <p>
                If You'd like to delete Cookies or instruct your web browser to
                delete or refuse Cookies, please visit the help pages of your web
                browser
              </p>
              <p>
                For the Chrome web browser, please visit this page from Google:{' '}
              </p>
              <p>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://support.google.com/accounts/answer/32050"
                >
                  https://support.google.com/accounts/answer/32050
                </a>
              </p>
              <p>
                For the Internet Explorer web browser, please visit this page from
                Microsoft:{' '}
              </p>
              <p>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc"
                >
                  http://support.microsoft.com/kb/278835
                </a>
              </p>
              <p>
                For the Firefox web browser, please visit this page from Mozilla:{' '}
              </p>
              <p>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US"
                >
                  https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored
                </a>
              </p>
              <p>
                For the Safari web browser, please visit this page from Apple:{' '}
              </p>
              <p>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://support.apple.com/en-am/guide/safari/sfri11471/mac"
                >
                  https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
                </a>
              </p>
              <p>
                For any other web browser, please visit your web browser's
                official web pages.
              </p>
              <h4>More Information about Cookies</h4>
              <p>You can learn more about cookies: What Are Cookies?</p>
              <h4>Contact Us</h4>
              <p>
                If you have any questions about this Cookies Policy, You can
                contact us:
              </p>
              <p>By visiting this page on our website: robert-change.com</p>
            </div>
            <div className="privacy__text"></div>
          </div>
        </div>
      </div>
    </AppWrapper>
  )
}
