import { useTranslation } from "react-i18next";

export let Country = () => {
  const { t } = useTranslation();

  let reasons = [
    `${t("Only_in_3_clicks")}`,
    `${t("Easy_to_use")}`,
    `${t("Better_prices")}`,
    `${t("Comfortable")}`,
    `${t("From_anywhere_you_want")}`,
    `${t("Special_offer")}`,
    `${t("Аmazing service")}`,
    `${t("High_security_standards")}`,
    `${t("w_transfer")}`,
  ];

  return reasons;
};
