import { useEffect, useState } from 'react'
import { Routes, Route } from 'react-router'
import { UseRecommendedLoginContext } from '../context/messgecontext'
import { privateroute, publicroute } from './Routing'

export const Routing: React.FC = () => {
  let { state } = UseRecommendedLoginContext()
  const [isLoged, setIsLoged] = useState(
    localStorage.getItem('isLoged') === 'true',
  )

  useEffect(() => {
    if (state.admin) setIsLoged(localStorage.getItem('isLoged') === 'true')
  }, [state.admin])

  return (
    <div>
      <Routes>
        {publicroute.map((u) => (
          <Route key={u.path} path={u.path} element={<u.element />} />
        ))}
        {isLoged &&
          privateroute.map((u) => (
            <Route key={u.path} path={u.path} element={<u.element />} />
          ))}
      </Routes>
    </div>
  )
}
