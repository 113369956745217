
import { useTranslation } from 'react-i18next'
import yellow from '../image/backgroundyellow.svg'
import { Country } from '../util/State'

const { REACT_APP_NAME } = process.env

export const Reasons: React.FC = () => {
 let reasons=Country()
 const { t } = useTranslation();
  return (
    <div className="reasons">
      <div className="reasons__position">
        <h1 className={`reasons__${REACT_APP_NAME}-h1`}>
          <p>{t('9_reasons_to_send')} </p>
          <p>{t('money_home_with')}</p>
          <p>{t('the_TM_App')}</p>
        </h1>
        <div className="reasons__design">

          <div className="reasons__border"></div>
          <img src={yellow} alt="..loading" className='reasons__shadow' />
          <div ></div>
          <div className="reasons__list">
            {reasons?.map((u) => (
              <ul key={u}>
                <li>{u}</li>
              </ul>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
