import React from 'react'
import { Link } from 'react-router-dom'
import { UseRecommendedLoginContext } from '../../context/messgecontext'
import { action } from '../../models/logincontext'
import { NavigateProps } from '../../models/Navigationtype'


export const Navigatesite: React.FC<NavigateProps> = ({
  text,
  id,
  path,
  className,
}) => {
  const { setState } = UseRecommendedLoginContext()
  let goTo = async () => {
    setState({ type: action.id, id: id })
  }

  return (
    <div className={className} onClick={() => goTo()}>
      <Link to={path}>{text}</Link>
    </div>
  )
}
