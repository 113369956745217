import { About } from './About'
import { Mission } from './Mission'
import { Antifraud } from './Antifraud'
import { UseRecommendedLoginContext } from '../context/messgecontext'
import { useRef, useEffect, useState } from 'react'
import { action } from '../models/logincontext'
import { animateScroll as scroll } from 'react-scroll'
import { AppWrapper } from '../components/AppWrapper'

export const Company: React.FC = () => {
  const { state, setState } = UseRecommendedLoginContext()
  const copnayref = useRef(null)
  let id = state.id;
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    let timer: any = null;
    if (isLoaded) {
      let doc = document.getElementById(id)
      timer = setTimeout(() => {
        if (doc) {
          scroll.scrollTo(doc?.offsetTop - 90, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
          })
        }
        setState({ type: action.id, id: '' })
      }, 0)
    }

    return () => clearTimeout(timer);
  }, [id, copnayref, setState, isLoaded])
  useEffect(() => {
    setIsLoaded(true);
    window.scrollTo(0, 0)
  }, [])
  return (
    <AppWrapper>
      <div className="company" ref={copnayref}>
        <About />
        <Mission />
        <Antifraud />
      </div>
    </AppWrapper>
  )
}
