import { useTranslation } from 'react-i18next'
import WhiteLabel from '../util/WhiteLabel'

export const Antifraud:React.FC=()=>{
  const { t } = useTranslation()
  let{ ABOUT_ANTIFRAUD_PIC,APP_NAME}=WhiteLabel()
  return <div id="target5">
          <div className="company__mission">
            <div>
              <div className="company__info-pos2">
                <div className={`company__${APP_NAME}-info2`}>
                  <h1> {t('We_keep_you_safe')}</h1>
                  <div className="company__fraud">
                    <p>{t('Anti_Fraud_m')} </p>
                    <p>{t('Laundering')}</p>
                    <div className="company__texts">{t('text_dot')}</div>
                  </div>
                  <div className="company__text-info">{t('puzzle_text')}</div>
                </div>
              </div>
            </div>
            <div>
            <img src={ABOUT_ANTIFRAUD_PIC} alt="no" className="company__currency-img" />
            </div>
          </div>
  </div>
}