import { useState } from 'react'
import cookie from '../image/icons/cookie.svg'

export const Cookie: React.FC = () => {
  let value = localStorage.getItem('cookie')
  let [cookiepos, setcookiepos] = useState<any>(!!value)
  const allow = () => {
    localStorage.setItem('cookie', 'true')
    setcookiepos(true)
  }

  return (
    <div className="cookie">
      {!cookiepos && (
        <div className={'cookie__position'}>
          <div className="cookie__tittle">
            <img src={cookie} alt="..loading" className="cookie__img" />
            <p>
              We use cookies to improve your experience. By clicking agree. you
              allow us such use.
            </p>
            <button className="cookie__button-c" onClick={allow}>
              agree
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
