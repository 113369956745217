export enum action {
  isAuth = 'isAuth',
  isLoading = 'isLoading',
  setLogin = 'setLogin',
  setPassword = 'setPassword',
  signin = 'signin',
  shadow = 'shadow',
  navigate = 'navigate',
  id = 'id',
  cookie = 'cookie',
  inAdmin = 'inAdmin',
  admin = 'admin',
}

export interface isAuth {
  type: action.isAuth
  isAuth: boolean
}
export interface Admin {
  type: action.admin
  admin: boolean | string | null
}
export interface setCookie {
  type: action.cookie
  cookie?: boolean
}
export interface SetId {
  type: action.id
  id?: string
}
export interface setShadow {
  type: action.shadow
  shadow: boolean
}
export interface setLogin {
  type: action.setLogin
  login: boolean
}
export interface setPassword {
  type: action.setPassword
  password: string
}
export interface isLoading {
  type: action.isLoading
  isLoading: boolean
}
export interface setsignin {
  type: action.signin
  signin: boolean
}
export interface setNavigation {
  type: action.navigate
  navigationTarget: any
}

export interface inAdmin {
  type: action.inAdmin
  inAdmin: any
}
export type Action =
  | isAuth
  | isLoading
  | setLogin
  | setPassword
  | setsignin
  | setShadow
  | setNavigation
  | SetId
  | setCookie
  | inAdmin
  | Admin

export interface Statetype {
  isAuth: boolean
  isLoading: boolean
  login: string
  password: string
  signin: boolean
  shadow: boolean
  navigationTarget: boolean
  id?: any
  cookie?: any
  inAdmin?: boolean
  admin?: boolean | any
}

export const initialState: Statetype = {
  isAuth: false,
  isLoading: false,
  login: '',
  password: '',
  signin: true,
  shadow: false,
  navigationTarget: false,
  id: '',
  cookie: false,
  inAdmin: false,
  admin: false,
}
