
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { Contactform } from '../components/Contactform'
import { ThankYou } from './ThankyouPage'
import { UseRecommendedLoginContext } from '../context/messgecontext'
import WhiteLabel from '../util/WhiteLabel'
import { action } from '../models/logincontext'
import { AppWrapper } from '../components/AppWrapper'

export const Contact: React.FC = () => {
  const { t } = useTranslation()
  useEffect(() => {
    window.scrollTo(0, 0)
    setState({type:action.isAuth,isAuth:false})
  }, [])
  let { state,setState } = UseRecommendedLoginContext()
  let {
    CONTACT_US_PIC_LEFT,
    CONTACT_US_PIC_RIGHT,
    CONTACT_US_PATH_PIC,
    CONTACT_US_PATH,
    APP_NAME,
  } = WhiteLabel()
  return (
    <AppWrapper>
    <div className="contact">
      <div className={`contact__${APP_NAME}-position`}>
        <div>
          <img
            src={CONTACT_US_PIC_LEFT}
            alt="...loading"
            className="contact__robo-img"
          />
        </div>
        <div className="contact__info-pos">
          <div className={`contact__${APP_NAME}-info`}>
            <h1>{t('Contact_us')}</h1>
            <div>
              <div className="contact__texts-dot">{t('text_dot')}</div>
            </div>
            <div>
              <p className="contact__text-info-c">{t('App-contact-text')}</p>
            </div>
            <div className="contact__loc-pos">
              <div className="contact__lock">
                <img src={CONTACT_US_PATH_PIC} alt="no" />
                <a rel="noreferrer" target="_blank" href={CONTACT_US_PATH}>
                  {t('path_1')}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <img
            src={CONTACT_US_PIC_RIGHT}
            alt="...loading"
            className="contact__robo-img"
          />
        </div>
      </div>
      <div className={`contact__${APP_NAME}-input-bg`}>
        <div className="contact__question-pos"></div>
        <div className="contact__input">
          {state.isAuth ? (
            <div>
              <ThankYou />
            </div>
          ) : (
            <div>
              <Contactform />
            </div>
          )}
        </div>
      </div>
    </div>
    </AppWrapper>
  )
}
