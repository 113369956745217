import left from '../image/lmessage.svg'
import leftmobile from '../image/lmmesage.svg'
import right from '../image/rmessage.svg'
import WhiteLabel from '../util/WhiteLabel'

export const ThankYou: React.FC = () => {
  let{APP_NAME} = WhiteLabel()
  return (
    <div className="position">
      <img src={leftmobile} alt="..loading" className="thankyou__img-mob" />
      <div>
        <img src={left} alt="..loading" className="thankyou__img-m" />
      </div>
      <div className="thankyou">
        <p className={`thankyou__${APP_NAME}-tittle`}>THANK YOU!</p>
        <div className="thankyou__text-dot-message">. . . . . . .</div>
        <p className="thankyou__info">
          Thank you for contacting us! We will get in
        </p>
        <p className="thankyou__info"> touch with you shortly.</p>
      </div>
      <div>
        <img src={right} alt="..loading" className="thankyou__img-m" />
      </div>
    </div>
  )
}
