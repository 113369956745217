import { useTranslation } from 'react-i18next'
import WhiteLabel from '../util/WhiteLabel'
let { CURRENCY_EXCHANGE_SHADOW, CURRENCY_EXCHANGE_PIC,APP_NAME } = WhiteLabel()


export const Exchange = () => {
 
  let { t } = useTranslation()

  return (
    <div id="target2">
      <div className="exchange">
        <div>
          <img
            src={CURRENCY_EXCHANGE_SHADOW}
            alt="...loading"
            className="exchange__background-img"
          />
        </div>
        <div className="exchange__shadow-exchange">
          <div>
            <img
              src={CURRENCY_EXCHANGE_PIC}
              alt="...loading"
              className="exchange__img"
            />
          </div>
          <div className="exchange__shadow-position">
            <div className={`exchange__${APP_NAME}-titles`}>
              <p className="exchange__currency-p"> {t('Currency')}</p>
              <p> {t('Exchange')}</p>
            </div>

            <div>
              <div className="exchange__text-dot-d">{t('text_dot')}</div>
            </div>
            <div className="exchange__text-info-curency">
              <p>{t('exchage_text')}</p>
              <span>{t('bold_text')}</span>
              <div>{t('with_us')}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="exchange__text-info-curency-mob">
        <p>{t('exchage_text')}</p>
        <span>{t('bold_text')}</span>
        <p>{t('with_us')}</p>
      </div>
    </div>
  )
}
