import burger from '../image/icons/burger.svg'
import { Fakemobile } from '../models/Buttontype'

export const HeaderMobile: React.FC<Fakemobile> = ({ children }) => {
  return (
    <div>
      <img src={burger} alt="no" onClick={() => children()}  className="header__burger-pos"/>
    </div>
  )
}
