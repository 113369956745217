import { MenuProps } from '../../models/Menutypes'
import { Navigatesite } from '../Navigate/Navigate'
import { StateMenu } from '../Navigate/state/Routes'

export const ServiceMenu: React.FC<MenuProps> = ({ wrapperRef }) => {
  const service = StateMenu('services')
  return (
    <div>
      <div className={'header__services-menu'} ref={wrapperRef}>
        {service && service.map((item: any) => (
          <div key={item.id}>
            <Navigatesite path={item.to} text={item.name} id={item.id} />
          </div>
        ))}
      </div>
    </div>
  )
}
