import { MenuProps } from '../../models/Menutypes'
import { Navigatesite } from '../Navigate/Navigate'
import { StateMenu } from '../Navigate/state/Routes'

export const CompanyMenu: React.FC<MenuProps> = ({ wrapperRef }) => {
  let CompanyNavigate = StateMenu('companyheader')

  return (
    <div>
      <div className={'header__company-menu'} ref={wrapperRef}>
        {CompanyNavigate?.map((item,index) => (
          <div key={index}>
            <Navigatesite path={item.to} text={item.name} id={item.id} />
          </div>
        ))}
      </div>
    </div>
  )
}
