import fb from '../image/icons/fb.svg'
import loc from '../image/icons/locationIcon.svg'
import { useTranslation } from 'react-i18next'
import { Cookie } from './Cookiepage'
import WhiteLabel from '../util/WhiteLabel'
import { Navigatesite } from '../components/Navigate/Navigate'
import {
  StateMenu,
} from '../components/Navigate/state/Routes'


const Footer: React.FC = () => {
  const { t } = useTranslation()
  let { LOGO, CONTACT_US_PATH, SOCIAL_MEDIA_PATH } = WhiteLabel()
  const service =StateMenu("services")
  const company = StateMenu("company")
  

  return (
    <div className="footer">
      <div className="footer__menu">
        <div className="footer__card-top-money">
          <div>
            <img className="footer__top-money-img" src={LOGO} alt="noimage" />
          </div>

          <div>{t('footer_info_tex')}</div>
        </div>

        <div className="footer__position-sc">
          <div className="footer__services">
            <div>
              <p className="footer__services-title"> {t('Services')}</p>
            </div>
            {service && service.map((u:any) => (
              <div key={u.id}>
                <Navigatesite path={u.to} text={u.name} id={u.id} />
              </div>
            ))}
          </div>

          <div className="footer__company">
            <div>
              <p className="footer__services-title"> {t('Company')}</p>
            </div>
            {company && company.map((c:any) => (
              <div key={c.name}>
                <Navigatesite path={c.to} text={c.name} id={c.id} />
              </div>
            ))}
          </div>
          <div className="footer__social-media">
            <div className="footer__services-title"> {t('social')}</div>
            <div className="footer__icons">
              <a href={SOCIAL_MEDIA_PATH} rel="noreferrer" target="_blank">
                <img src={fb} alt="no" />
              </a>
            </div>
          </div>
          <div className="footer__path">
            <img src={loc} alt="no" />
            <a rel="noreferrer" href={CONTACT_US_PATH} target="_blank">
              {t('path_1')}
            </a>
          </div>
        </div>
      </div>
      <div className="footer__cookie-pos">
        <Cookie />
      </div>
    </div>
  )
}

export default Footer
