import { Link } from 'react-router-dom'
import { MenuProps } from '../../models/Menutypes'
import WhiteLabel from '../../util/WhiteLabel'
import { StateMenu } from '../Navigate/state/Routes'

export const MobileMenu: React.FC<MenuProps> = ({ wrapperRef, setMobil }) => {
  
  let { APP_NAME, MOBILE_MENU_CLOSE_PIC } = WhiteLabel()
  let Navigate = StateMenu('mobilemenu')
  
  return (
    <div>
      <div className={`header__${APP_NAME}-mobile-menu`} ref={wrapperRef}>
        <div>
          <img
            src={MOBILE_MENU_CLOSE_PIC}
            alt="loading"
            onClick={() => setMobil(false)}
            className="header__mobile-x"
          />
        </div>
        {Navigate?.map((mob) => (
          <div key={mob.src} className="header__mobile-icon">
            <img src={mob.src} alt="loading" />
            <Link to={mob.to}>{mob.name}</Link>
          </div>
        ))}
      </div>
    </div>
  )
}
