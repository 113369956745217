import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { AppWrapper } from '../components/AppWrapper'

import WhiteLabel from '../util/WhiteLabel'

const HomePage: React.FC = () => {
  const { t } = useTranslation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  let { BackgroundMob, HOME_GIF, HOME_BACKGROUND, APP_NAME } = WhiteLabel()

  return (
    <AppWrapper showFooter={true} showHeader={true}>
      <div
        className="home"
        style={{
          backgroundImage: `url(${HOME_BACKGROUND})`,
          backgroundSize: 'cover',
        }}
      >
        <div>
          <div className={`home__${APP_NAME}-titles`}>
            <img src={BackgroundMob} alt="no" className="home__money-img-mob" />
            <div className="home__box">
              <img src={HOME_GIF} alt="..loading" className="home__phone-gif" />
              <div className="home__shadow">
                <h1 className="home__title">{t('header_menu_help')}</h1>
                <div className="home__text-position">
                  <div className="home__text">
                    <p>{t('header_menu_our_relaible')}</p>
                    <p>{t('header_menu_our_products')} </p>
                    <p>{t('header_menu-about_trusted')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppWrapper>
  )
}

export default HomePage
