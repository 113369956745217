import { useTranslation } from "react-i18next"
import WhiteLabel from "../util/WhiteLabel"

export const About:React.FC=()=>{
  const { t } = useTranslation()
  let{ABOUT_US_PIC, APP_NAME}=WhiteLabel()
  return <div>
     <div id="target4">
       <div  className="company__position">
          <div className="company__info-pos">
            <div className={`company__${APP_NAME}-info`}>
              <h1>{t('About_us_UP')}</h1>
              <div>
                <div className="company__texts">{t('text_dot')}</div>
              </div>
              <div className="company__text-inform">{t('company_text')}</div>
            </div>
          </div>
          <div> 
            <img src={ABOUT_US_PIC} alt="no" className="company__currency-img" />
          </div>
       </div>
          <div className="company__text-mob1"></div>
        </div>
  </div>
}