import { useRef, useEffect, useState } from 'react'

import { Send } from './send'
import { Exchange } from './exchange'
import { RobertChange } from './RobertChange'
import { UseRecommendedLoginContext } from '../context/messgecontext'
import { action } from '../models/logincontext'
import { animateScroll as scroll } from 'react-scroll'
import { AppWrapper } from '../components/AppWrapper'

export const Services = () => {
  const { state, setState } = UseRecommendedLoginContext()
  const wrapperRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
  let id = state.id;
  useEffect(() => {
    let timer: any = null;
    if (isLoaded) {
      let doc = document.getElementById(id)
      timer = setTimeout(() => {
        if (doc) {
          scroll.scrollTo(doc?.offsetTop - 90, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
          })
        }
        setState({ type: action.id, id: '' })
      }, 0)
    }

    return () => clearTimeout(timer)

  }, [id, wrapperRef, setState, isLoaded])

  useEffect(() => {
    setIsLoaded(true);
  }, [])

  return (
    <AppWrapper>
      <div ref={wrapperRef}>
        <Send />
        <Exchange />
        <RobertChange />
      </div>
    </AppWrapper>
  )
}
