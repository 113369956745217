import { Reasons } from './reasons'
import WhiteLabel from '../util/WhiteLabel'

export const RobertChange = () => {
  let {  REASONS_EXCHANGE_SHADOW,  REASONS_EXCHANGE_PIC } = WhiteLabel()
  return (
    <div id="target3">
      <div className='product'>
        <img src={REASONS_EXCHANGE_SHADOW} alt="..loading"  className='product__shadow'/>
      <div className="product__reason">
        <div className="product__app9">
          <Reasons />
        </div>
        <img
          src={REASONS_EXCHANGE_PIC}
          className="product__background-img"
          alt="no"
        />
      </div>
      </div>
    </div>
  )
}
