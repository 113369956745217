import robert from '../image/icons/robert.svg'
import backgrond from '../image/mobilepage/framemob.png'
import phone from '../image/Gif/phone.gif'
import backgroundimage from '../image/homebackground.png'
import blue from '../image/backgroundblue.svg'
import globe from '../image/Gif/globe.gif'
import weigh from '../image/Gif/weigh.gif'
import shadow from '../image/shadowexchange.svg'
import phonebackground from '../image/Gif/phoneapp.gif'
import reasonsshadow from '../image/9shadow.svg'
import about from '../image/Gif/about.gif'
import ourfrom  from '../image/Gif/our.gif'
import phons from '../image/Gif/phons.gif'
import left from '../image/Gif/leftrobo.gif'
import right from '../image/Gif/rightrobo.gif'
import loc from '../image/icons/locationIcon.svg'
import x from '../image/icons/x.svg'
import servic from '../image/icons/services.svg'
import bug from '../image/icons/bug.svg'
import contact from '../image/icons/about.svg'

const { REACT_APP_NAME, REACT_APP_API_ENDPOINT } = process.env

const WhiteLabel = () => {
  let app = {
    RobertChange: {
      APP_NAME: REACT_APP_NAME,
      LOGO_NAME: 'robert_change',
      BASE_URL: REACT_APP_API_ENDPOINT,
      HOME_BACKGROUND: backgroundimage,
      HOME_GIF: phone,
      BackgroundMob: backgrond,
      LOGO: robert,
      GLOBAL_TRANSFER_SHADOW: blue,
      GLOBAL_TRANSFER_GIF: globe,
      CURRENCY_EXCHANGE_PIC: weigh,
      CURRENCY_EXCHANGE_SHADOW: shadow,
      REASONS_EXCHANGE_PIC: phonebackground,
      REASONS_EXCHANGE_SHADOW: reasonsshadow,
      ABOUT_US_PIC: about,
      ABOUT_OUR_MISSION_PIC: ourfrom,
      ABOUT_ANTIFRAUD_PIC:phons,
      CONTACT_US_PIC_LEFT:left,
      CONTACT_US_PIC_RIGHT:right,
      CONTACT_US_PATH_PIC:loc,
      CONTACT_US_PATH:"https://www.google.com/maps/place/Pinsker+15,+Netanya,+%D0%98%D0%B7%D1%80%D0%B0%D0%B8%D0%BB%D1%8C/@32.3263098,34.85643,17z/data=!3m1!4b1!4m5!3m4!1s0x151d6aaa721d9bd9:0x1d73f7dbba3096a1!8m2!3d32.3263053!4d34.8586187",
      SOCIAL_MEDIA_PATH:"https://www.facebook.com/pages/category/Currency-Exchange/Robert-Change-2055240548138835/",
      SPINNER_COLOR: '#575EF9',
      MOBILE_MENU_CLOSE_PIC:x,
      MOBILE_MENU_SERVICES_PIC:servic,
      MOBILE_MENU_COMPANY_PIC:bug,
      MOBILE_MENU_CONTACT_PIC:contact,
      APP_BACKGROUND_COLOR: '#575EF9',
      BLACK_COLOR: '#363636',
      GREY_COLOR: '#DADADA',
      PLACEHOLDER_COLOR: '#909090',
      LIGHT_GREY_COLOR: '#F4F6F8',
      TABS_BACKGROUND_COLOR: '#FFFFFF',
      TAB_LABEL_ACTIVE_TEXT_COLOR: '#363636',
      TAB_LABEL_ACTIVE_BACKGROUND_COLOR: '#F2F2F2',
      TAB_LABEL_INACTIVE_TEXT_COLOR: '#363636',
      WITHOUT_PASSWORD: true,
      WITHOUT_DELIVER_VIA: true,
    },
  }

  return app[REACT_APP_NAME as keyof typeof app]
}
export default WhiteLabel
