import { Error } from '../page/Error'
import HomePage from '../page/homePage'
import { Services } from '../page/services'
import { Company } from '../page/company'
import { Contact } from '../page/contact'
import { ObjectArray } from '../models/Pathtype'
import { Privacy } from '../page/privacypolicy'
import { CookiePrivacy } from '../page/Cookieprivacy'
import { SignInSide } from '../page/admin/Signin/Index'
import { Home } from '../page/admin/Home/Index'

export let publicroute: Array<ObjectArray> = [
  {
    path: '/',
    element: HomePage,
  },
  {
    path: '/services',
    element: Services,
  },
  {
    path: '/company',
    element: Company,
  },
  {
    path: '/*',
    element: Error,
  },
  {
    path: '/send',
    element: Services,
  },
  {
    path: '/contact',
    element: Contact,
  },
  {
    path: '/privacy',
    element: Privacy,
  },
  {
    path: '/home',
    element: HomePage,
  },
  {
    path: '/cookie',
    element: CookiePrivacy,
  },
  // {
  //   path: '/login',
  //   element: SignInSide,
  // },
]

export let privateroute: Array<ObjectArray> = [
  {
    path: '/admin',
    element: Home,
  },
  {
    path: '/*',
    element: Error,
  },
]
