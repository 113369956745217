import { useTranslation } from 'react-i18next'
import WhiteLabel from '../util/WhiteLabel'

export const Mission: React.FC = () => {
  const { t } = useTranslation()
  let { ABOUT_OUR_MISSION_PIC, APP_NAME } = WhiteLabel()

  return (
    <div>
      <div className="company__mission">
        <div>
          <img
            src={ABOUT_OUR_MISSION_PIC}
            className="company__phons-img"
            alt="no"
          />
        </div>
        <div className={`company__${APP_NAME}-app-info`}>
          <p>{t('Our_Mission')}</p>
          <p>{t('and_Vision')}</p>
          <div className="company__texts">
            <div>{t('text_dot')}</div>
          </div>
          <div className="company__li-pos">
            <ul>
              <li>{t('to_provide_f')}</li>
            </ul>
            <ul>
              <li>{t('to_provide_h')}</li>
            </ul>
            <ul>
              <li> {t('to_offer_z')}</li>
            </ul>
            <ul>
              <li>{t('to_offer_b')}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
