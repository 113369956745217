import React from 'react'
import { wrapper } from '../models/AppWraper'
import Footer from '../page/footer'
import Header from '../page/headerPage'



export const AppWrapper: React.FC<wrapper> = ({
  children,
  showHeader = true,
  showFooter = true,
}) => {
  return (
    <div>
      {showHeader && <Header />}
      {children}
      {showFooter && <Footer />}
    </div>
  )
}
