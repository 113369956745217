import React from 'react'
import ReactDOM from 'react-dom'
import './styles/style.scss'
import App from './App'
import './fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf'
import './fonts/Montserrat/Montserrat-VariableFont_wght.ttf'
import reportWebVitals from './reportWebVitals'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import common_il from './languages/il/translate.json'
import common_en from './languages/en/translate.json'
import common_ru from './languages/ru/translate.json'

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)

  .init({
    supportedLngs: ['en', 'il', 'ru'],
    fallbackLng: 'en',
    resources: {
      il: { common: common_il },
      en: { common: common_en },
      ru: { common: common_ru },
    },
    ns: ['common'],
    defaultNS: 'common',
    detection: {
      order: ['path', 'subdomain', 'localStorage', 'cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/languages/{{lng}}/translate.json',
    },
    react: { useSuspense: false },
  })
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
