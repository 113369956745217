import { useTranslation } from 'react-i18next'
import { NavigateType } from '../../../models/Navigationtype'
import WhiteLabel from '../../../util/WhiteLabel'

export const StateMenu = (name: string) => {
  const { t } = useTranslation()
  
  let {
    MOBILE_MENU_SERVICES_PIC,
    MOBILE_MENU_COMPANY_PIC,
    MOBILE_MENU_CONTACT_PIC,
  } = WhiteLabel()
  
  let navigate: NavigateType = {
  
    services: [
      {
        name: `${t('Global-transfers')}`,
        to: '/services',
        id: 'target1',
      },
      {
        name: `${t('Currency_Exchange')}`,
        to: '/services',
        id: 'target2',
      },
      {
        name: `${t('Robert_Change_App')}`,
        to: '/services',
        id: 'target3',
      },
    ],
    company: [
      {
        name: `${t('About_US')}`,
        to: '/company',
        id: 'target4',
      },
      {
        name: `${t('Anti_Fraud')}`,
        to: '/company',
        id: 'target5',
      },
      {
        name: `${t('Privecy_Policy')}`,
        to: '/privacy',
      },
      {
        name: `${t('Cookie_Policy')}`,
        to: '/cookie',
      },
      {
        name: `${t('header_menu_contact_us')}`,
        to: '/contact',
      },
    ],
    mobilemenu: [
      {
        name: `${t('Services')}`,
        to: '/services',
        src: MOBILE_MENU_SERVICES_PIC,
      },
      {
        name: `${t('Company')}`,
        to: '/company',
        src: MOBILE_MENU_COMPANY_PIC,
      },
      {
        name: `${t('header_menu_contact_us')}`,
        to: '/contact',
        src: MOBILE_MENU_CONTACT_PIC,
      },
    ],
    companyheader: [
      {
        name: `${t('About_US')}`,
        to: '/company',
        id: 'target4',
      },
      {
        name: `${t('Anti_Fraud')}`,
        to: '/company',
        id: 'target5',
      },
      {
        name: `${t('header_menu_contact_us')}`,
        to: '/contact',
      },
    ],
  }

  return navigate[name as keyof typeof navigate]
}
